const setPageTitle = (title) => {
  document.title = title
    ? `${title} | OPEXIFY`
    : "OPEXIFY | We create value through our smart solutions";
};

const scrollToTop = () => {
  window.scrollTo(0, 0);
};

const LANGUAGE = {
  EN: "en",
  FR: "fr",
  LU: "lu",
};

export { setPageTitle, scrollToTop, LANGUAGE };
