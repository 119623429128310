import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import templateAnimation from "../../../importer/templateAnimation";
import templateUtil from "../../../importer/templateUtil";
import AppFooter from "../../components/AppFooter";
import AppHeader from "../../components/AppHeader";
import CookieBanner from "../../components/CookieBanner";
import { scrollToTop, setPageTitle } from "../../utils";

const PrivacyPolicy = () => {
    useEffect(() => {
        setPageTitle("Our Cookies Policy");
        templateUtil();
        templateAnimation();
        scrollToTop();
    }, []);

    return (
        <div>
            <AppHeader />
            <section id="privacy-policy">
                <div id="main" className="wrapper style1">
                    <div className="container">
                        <header className="major">
                            <h2>
                                <FormattedMessage id="pagePrivacyPolicy.title" />
                            </h2>
                        </header>
                        <div className="row gtr-150">
                            <div className="col-8 col-12-medium imp-medium">
                                <section>
                                    <h3>
                                        <FormattedMessage id="pagePrivacyPolicy.sectionCookies.title" />
                                    </h3>
                                    <p>
                                        <FormattedMessage id="pagePrivacyPolicy.sectionCookies.one" />
                                    </p>
                                </section>
                                <section>
                                    <h3 id="cookie-policy">
                                        <FormattedMessage id="pagePrivacyPolicy.sectionPolicy.title" />
                                    </h3>
                                    <h4>
                                        <FormattedMessage id="pagePrivacyPolicy.sectionPolicy.title.cookie" />
                                    </h4>
                                    <p>
                                        <FormattedMessage id="pagePrivacyPolicy.sectionPolicy.one" />
                                    </p>
                                    <h4>
                                        <FormattedMessage id="pagePrivacyPolicy.sectionPolicy.title.useCookie" />
                                    </h4>
                                    <p>
                                        <FormattedMessage id="pagePrivacyPolicy.sectionPolicy.two" />
                                    </p>
                                    <h4>
                                        <FormattedMessage id="pagePrivacyPolicy.sectionPolicy.title.typeCookie" />
                                    </h4>
                                    <p>
                                        <b>
                                            <FormattedMessage id="pagePrivacyPolicy.sectionPolicy.three" />
                                        </b>
                                    </p>
                                    <p>
                                        <FormattedMessage id="pagePrivacyPolicy.sectionPolicy.four" />
                                    </p>
                                    <div className="table-wrapper">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Cookie type</th>
                                                    <th>Function/purpose</th>
                                                    <th>Retention time</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>_gid</td>
                                                    <td>
                                                        Google Analytics for
                                                        statistical purposes
                                                    </td>
                                                    <td>1 day</td>
                                                </tr>

                                                <tr>
                                                    <td>_gat</td>
                                                    <td>
                                                        Google Analytics for
                                                        statistical purposes
                                                    </td>
                                                    <td>1 minute</td>
                                                </tr>
                                                <tr>
                                                    <td>_ga</td>
                                                    <td>
                                                        Google Analytics, used
                                                        to distinguish users
                                                    </td>
                                                    <td>2 years</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <p>
                                        <FormattedMessage id="pagePrivacyPolicy.sectionPolicy.five" />
                                    </p>
                                    <p>
                                        <FormattedMessage id="pagePrivacyPolicy.sectionPolicy.six" />
                                    </p>
                                    <p>
                                        <FormattedMessage id="pagePrivacyPolicy.sectionPolicy.seven" />
                                    </p>
                                    <p>
                                        <b>
                                            <FormattedMessage id="pagePrivacyPolicy.sectionPolicy.eight" />
                                        </b>
                                    </p>
                                    <p>
                                        <FormattedMessage id="pagePrivacyPolicy.sectionPolicy.nine" />
                                    </p>
                                    <p>
                                        <b>Google Analytics</b>
                                    </p>
                                    <p>
                                        <FormattedMessage id="pagePrivacyPolicy.sectionPolicy.ten" />
                                    </p>
                                    <p>
                                        <FormattedMessage
                                            id="pagePrivacyPolicy.sectionPolicy.eleven"
                                            values={{
                                                link: (
                                                    <a
                                                        href="http://tools.google.com/dlpage/gaoptout"
                                                        target="_blank"
                                                    >
                                                        http://tools.google.com/dlpage/gaoptout
                                                    </a>
                                                ),
                                            }}
                                        />
                                    </p>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <AppFooter />
            <CookieBanner />
        </div>
    );
};

export default PrivacyPolicy;
