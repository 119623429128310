import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import templateAnimation from "../../../importer/templateAnimation";
import templateUtil from "../../../importer/templateUtil";
import { setPageTitle } from "../../utils";
import AppHeader from "../../components/AppHeader";
import AppFooter from "../../components/AppFooter";
import CookieBanner from "../../components/CookieBanner";
import "./styles.css";

const articles = [
    {
        title:
            "What is Robotic Process Automation (RPA) and how can it benefit your business/organization ?",
        extract:
            "For starters, RPA involves no physical or mechanical robots. Rather, the “robot” in robotic process automation is software robots or “bots” running on a physical or virtual machine. In practice, RPA automates everyday processes that require people to do a high volume of repetitive data work.",
        author: "Roger Tafotie",
        image: "https://miro.medium.com/max/700/0*2YnvOrKiMBzkTEbs",
        link:
            "https://medium.com/@opexify/what-is-robotic-process-automation-rpa-and-how-can-it-benefit-your-business-organization-f7c72064c2ff",
    },
    {
        title: "Why APIs Are Fundamental to Your Business",
        extract:
            "To explain why APIs must be in your digital strategy’s top 3 priorities, I’m going to start by explaining what an API is. If you are knowledgeable enough, go straight to the section “Why Business Leaders must grasp the decisive nature of APIs?”",
        author: "Yannick Huchard",
        image: "https://miro.medium.com/max/700/1*QxBo3lb24px-Fp59UmlSwA.jpeg",
        link:
            "https://medium.com/@yannick.huchard/why-apis-are-fundamental-to-your-business-613184eb4f8",
    },
];

const Blog = () => {
    useEffect(() => {
        setPageTitle("Blog");
        templateUtil();
        templateAnimation();
        document.documentElement.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <AppHeader />
            <div className="container" id="blog">
                <header className="major">
                    <h2>Blog</h2>
                </header>
                {articles.map((item, i) => (
                    <article key={i}>
                        <div className="row">
                            <div className="col-6 col-12-xsmall">
                                <img src={item.image} />
                            </div>
                            <div className="col-6 col-12-xsmall">
                                <div className="ressource">
                                    <h3>{item.title}</h3>
                                    <p>{item.extract}</p>
                                    <p className="author">by {item.author}</p>
                                    <p>
                                        <a
                                            href={item.link}
                                            target="_blank"
                                            className="button"
                                        >
                                            <FormattedMessage id="common.more" />
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <hr />
                    </article>
                ))}
            </div>
            <AppFooter />
            <CookieBanner />
        </div>
    );
};

export default Blog;
