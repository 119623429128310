import React, { useState, useRef, useEffect } from "react";
import tippy from "tippy.js";
import * as firebase from "firebase";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import "./styles.css";

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const OPERATIONS = [
    {
        operation: "2 + 8",
        result: 10,
    },
    {
        operation: "3 * 7",
        result: 21,
    },
    {
        operation: "144 - 12",
        result: 132,
    },
];
const tippyConfig = {
    animation: "shift-away",
    content:
        "Please type the result of the operation in the box on the left side.",
};

const generateRandomNumber = () => {
    return Math.floor(Math.random() * Math.floor(OPERATIONS.length));
};

const ContactForm = () => {
    const [nameInput, setNameInput] = useState("");
    const [emailInput, setEmailInput] = useState("");
    const [messageInput, setMessageInput] = useState("");
    const [captchaInput, setCaptchaInput] = useState("");
    const [hasNameInputError, setHasNameInputError] = useState(false);
    const [hasEmailInputError, setHasEmailInputError] = useState(false);
    const [hasMessageInputError, setHasMessageInputError] = useState(false);
    const [hasCaptchaInputError, setHasCaptchaInputError] = useState(false);
    const [currentOperation, setCurrentOperation] = useState(
        generateRandomNumber()
    );
    const [redoButtonClasses, setRedoButtonClasses] = useState(
        "icon solid alt fa-redo"
    );
    const [isCanvasLoaded, setIsCanvasLoaded] = useState(true);
    const canvaRef = useRef(null);

    useEffect(() => {
        displayCaptcha();
        tippy("#info-mobile", {
            ...tippyConfig,
            trigger: "click",
        });
        tippy("#info", tippyConfig);
    }, []);

    const reloadOperation = () => {
        setIsCanvasLoaded(false);
        setCurrentOperation(generateRandomNumber());
        setRedoButtonClasses("icon solid alt fa-redo rotate");

        setTimeout(() => {
            setRedoButtonClasses("icon solid alt fa-redo");
            setIsCanvasLoaded(true);
            displayCaptcha();
        }, 1000);
    };

    const displayCaptcha = () => {
        const context = canvaRef.current.getContext("2d");

        context.save();
        context.font = "30px Palatino";
        context.strokeText(OPERATIONS[currentOperation].operation, 0, 40);
    };

    const handleInputChange = (event) => {
        const { value, name } = event.target;

        switch (name) {
            case "name":
                setHasNameInputError(false);
                setNameInput(value);
                break;

            case "email":
                setHasEmailInputError(false);
                setEmailInput(value);
                break;

            case "message":
                setHasMessageInputError(false);
                setMessageInput(value);
                break;

            case "captcha":
                setHasCaptchaInputError(false);
                setCaptchaInput(value);
                break;

            default:
                break;
        }
    };

    const hasFormErrors = () => {
        const nameError = nameInput.length === 0;
        const emailError =
            emailInput.length === 0 || !EMAIL_REGEX.test(emailInput);
        const messageError = messageInput.length === 0;
        const captchaError =
            Number(captchaInput) !== OPERATIONS[currentOperation].result;

        setHasNameInputError(nameError);
        setHasEmailInputError(emailError);
        setHasMessageInputError(messageError);
        setHasCaptchaInputError(captchaError);

        return nameError || emailError || messageError || captchaError;
    };

    const handleOnSubmit = (event) => {
        event.preventDefault();

        if (!hasFormErrors()) {
            firebase.database().ref("contactmessage").push({
                name: nameInput,
                email: emailInput,
                message: messageInput,
                date: DateTime.local().toString(),
            });
            resetForm();
        }
    };

    const resetForm = () => {
        setNameInput("");
        setEmailInput("");
        setMessageInput("");
        setCaptchaInput("");
        setHasNameInputError(false);
        setHasEmailInputError(false);
        setHasMessageInputError(false);
        setHasCaptchaInputError(false);
    };

    const handleOnReset = (event) => {
        event.preventDefault();
        resetForm();
    };

    const renderCanvas = () => {
        return isCanvasLoaded ? (
            <canvas ref={canvaRef} width="150" height="60" />
        ) : (
            <div className="canva-placeholder" />
        );
    };

    return (
        <form onSubmit={handleOnSubmit} onReset={handleOnReset} noValidate>
            <div className="row gtr-uniform gtr-50">
                <div className="col-6 col-12-xsmall">
                    <input
                        type="text"
                        name="name"
                        id="name"
                        value={nameInput}
                        placeholder="Name"
                        onChange={handleInputChange}
                        className={hasNameInputError ? "has-error" : ""}
                    />
                </div>
                <div className="col-6 col-12-xsmall">
                    <input
                        type="email"
                        name="email"
                        id="email"
                        value={emailInput}
                        placeholder="Email"
                        onChange={handleInputChange}
                        className={hasEmailInputError ? "has-error" : ""}
                    />
                </div>
                <div className="col-12">
                    <textarea
                        name="message"
                        id="message"
                        placeholder="Enter your message"
                        rows="6"
                        value={messageInput}
                        onChange={handleInputChange}
                        className={hasMessageInputError ? "has-error" : ""}
                    />
                </div>
                <div className="col-6">
                    <div className="row">
                        <div className="col-9">
                            <input
                                type="text"
                                name="captcha"
                                id="captcha"
                                value={captchaInput}
                                onChange={handleInputChange}
                                autoComplete="off"
                                className={
                                    hasCaptchaInputError ? "has-error" : ""
                                }
                            />
                        </div>
                        <div className="col-3">
                            <button
                                className="icon solid alt fa-info-circle"
                                id="info"
                            >
                                <span className="label">
                                    <FormattedMessage id="contactform.info" />
                                </span>
                            </button>
                            <button
                                className="icon solid alt fa-info-circle"
                                id="info-mobile"
                            >
                                <span className="label">
                                    <FormattedMessage id="contactform.info" />
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col-6 canvas-wrapper">
                    {renderCanvas()}
                    <button
                        className={redoButtonClasses}
                        onClick={reloadOperation}
                    >
                        <span className="label">
                            <FormattedMessage id="contactform.redo" />
                        </span>
                    </button>
                </div>
                <div className="col-12-xsmall notice">
                    <FormattedMessage id="contactform.notice" />{" "}
                    <Link to="/privacy-notice">
                        <b>
                            <FormattedMessage id="contactform.privacyNotice" />
                        </b>
                    </Link>
                    .
                </div>
                <div className="col-12">
                    <ul className="actions">
                        <li>
                            <input
                                type="submit"
                                value="Send Message"
                                className="primary"
                            />
                        </li>
                        <li>
                            <input type="reset" value="Reset" />
                        </li>
                    </ul>
                </div>
            </div>
        </form>
    );
};

export default ContactForm;
