import React from "react";
import { FormattedMessage } from "react-intl";
import photoScottGraham from "../../assets/img/scott-graham-unsplash.jpg";
import "./styles.css";

const SectionSolutions = () => (
    <section id="solutions" className="spotlight style1 left">
        <span className="image fit main">
            <img src={photoScottGraham} alt="pic 03" />
        </span>
        <div className="content">
            <header>
                <h2 className="text-primary text-uppercase">
                    <FormattedMessage id="sectionsolutions.title" />
                </h2>
            </header>
            <div className="box alt">
                <div className="row gtr-uniform">
                    <section className="col-12">
                        <ul className="alt">
                            <li>
                                <h3 className="product-name text-primary">
                                    Tikki
                                </h3>
                                <FormattedMessage id="sectionsolutions.tikki.description" />
                                {/* <a
                  href="https://opexify.io/"
                  target="_blank"
                  className="button small"
                >
                  Read More
                </a> */}
                            </li>
                            <li>
                                <h3 className="product-name text-primary">
                                    Uppya
                                </h3>
                                <FormattedMessage id="sectionsolutions.uppya.description" />
                                <br />
                                {/* <a
                  href="https://opexify.io/"
                  target="_blank"
                  className="button small"
                >
                  Read More
                </a> */}
                            </li>
                        </ul>
                    </section>
                </div>
            </div>
        </div>
        <a href="#services" className="goto-next scrolly">
            <FormattedMessage id="common.next" />
        </a>
    </section>
);

export default SectionSolutions;
