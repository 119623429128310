import React from "react";
import blockchainAcademyLogo from "../../assets/img/blockchain-academy-logo.png";
import "./styles.css";

const SectionPartners = () => (
  <section id="partners" className="wrapper style2 special fade">
    <div className="container">
      <header>
        <h2>PARTNER</h2>
        <p className="mt-md">
          <a href="https://www.the-blockchain-academy.com/" target="_blank">
            <img
              src={blockchainAcademyLogo}
              alt="The Blockchain Academy®"
              className="logo-tba"
            />
          </a>
        </p>
      </header>
    </div>
  </section>
);

export default SectionPartners;
