import React from "react";
import { FormattedMessage } from "react-intl";
import photoHeadway from "../../assets/img/headway.jpg";

const SectionServices = () => (
    <section id="services" className="spotlight style2 right">
        <span className="image fit main">
            <img src={photoHeadway} alt="pic 03" />
        </span>
        <div className="content">
            <header>
                <h2 className="text-primary text-uppercase">
                    <FormattedMessage id="sectionservices.title" />
                </h2>
                <p>
                    <FormattedMessage id="sectionservices.description" />
                </p>
            </header>
            <h3 className="text-primary">
                <FormattedMessage id="sectionservices.service.software" />
            </h3>
            <ul className="alt">
                <li>
                    <FormattedMessage id="sectionservices.service.software.one" />
                </li>
                <li>
                    <FormattedMessage id="sectionservices.service.software.two" />
                </li>
                <li>
                    <FormattedMessage id="sectionservices.service.software.three" />
                </li>
                <li>
                    <FormattedMessage id="sectionservices.service.software.four" />
                </li>
                <li>
                    <FormattedMessage id="sectionservices.service.software.five" />
                </li>
            </ul>
        </div>
        <a href="#three" className="goto-next scrolly">
            <FormattedMessage id="common.next" />
        </a>
    </section>
);

export default SectionServices;
