import React from "react";
import ContactForm from "../ContactForm";
import "./styles.css";

const SectionContact = () => (
  <section id="six" className="wrapper special fade">
    <div className="container">
      <header>
        <h2 className="text-uppercase">Contact</h2>
      </header>
      <div className="row">
        <div className="col-6 col-12-small">
          <ContactForm />
        </div>
        <div className="col-6 col-12-small">
          <p className="address">
            <span className="icon fa-map-marker solid" />
            5 rue des Champs
            <br />
            L-4718 Pétange
            <br />
            Luxembourg
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default SectionContact;
