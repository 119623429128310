import React from "react";
import { FormattedMessage } from "react-intl";
import tikkiLogo from "../../assets/img/tikki-text-logo.png";
import "./styles.css";

const SectionTikkiIntro = () => (
  <section id="banner">
    <div className="content">
      <header>
        <h2 className="text-primary">Tikki</h2>
        <p>Effortless process orchestrator for Treasury Management</p>
      </header>
      <span className="image">
        <img src={tikkiLogo} alt="Tikki" />
      </span>
    </div>
    <a href="#one" className="goto-next scrolly">
      <FormattedMessage id="common.next" />
    </a>
  </section>
);

export default SectionTikkiIntro;
