import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import HomepageAppHeader from "../../components/HomepageAppHeader";
import Banner from "../../components/Banner";
import SectionOne from "../../components/SectionOne";
import SectionServices from "../../components/SectionServices";
import SectionThree from "../../components/SectionThree";
import SectionTeam from "../../components/SectionTeam";
import SectionSolutions from "../../components/SectionSolutions";
import SectionContact from "../../components/SectionContact";
import SectionResources from "../../components/SectionResources";
import SectionPartners from "../../components/SectionPartners";
import AppFooter from "../../components/AppFooter";
import CookieBanner from "../../components/CookieBanner";
import templateUtil from "../../../importer/templateUtil";
import templateAnimation from "../../../importer/templateAnimation";
import { setPageTitle } from "../../utils";

const Home = () => {
  const [userInput, setUserInput] = useState("");
  const history = useHistory();
  const password = "opexify";

  useEffect(() => {
    setPageTitle();
    templateUtil();
    templateAnimation();
  }, []);

  const openSecretPage = (e) => {
    setUserInput(`${userInput}${String.fromCharCode(e.keyCode).toLowerCase()}`);
  };

  if (userInput === password) {
    history.push("/mail");
  }

  return (
    <div onKeyDown={openSecretPage} tabIndex="0">
      <HomepageAppHeader />
      <Banner />
      <SectionSolutions />
      <SectionServices />
      <SectionThree />
      <SectionOne />
      <SectionResources />
      <SectionTeam />
      <SectionPartners />
      <SectionContact />
      <AppFooter />
      <CookieBanner />
    </div>
  );
};

export default Home;
