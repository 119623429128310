import React from "react";
import { FormattedMessage } from "react-intl";
import photoRodison from "../../assets/img/photo-rodison-kutsaev.jpeg";
import "./styles.css";

const Banner = () => (
  <section id="banner">
    <div className="content">
      <header>
        <h2 className="text-primary">Opexify</h2>
        <p>
          <FormattedMessage id="banner.title" />
        </p>
      </header>
      <span className="image">
        <img src={photoRodison} alt="pic 01" />
      </span>
    </div>
    <a href="#solutions" className="goto-next scrolly">
      <FormattedMessage id="common.next" />
    </a>
  </section>
);

export default Banner;
