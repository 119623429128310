import React, { useState, useEffect } from "react";
import * as firebase from "firebase";
import { DateTime } from "luxon";
import { FormattedMessage } from "react-intl";
import templateAnimation from "../../../importer/templateAnimation";
import templateUtil from "../../../importer/templateUtil";
import { setPageTitle } from "../../utils";
import "./styles.css";

const Mail = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [isLogged, setIsLogged] = useState(false);
    const [mails, setMails] = useState([]);

    useEffect(() => {
        setPageTitle("Mail");
        templateUtil();
        templateAnimation();
    }, []);

    useEffect(() => {
        if (isLogged) {
            firebase
                .database()
                .ref("contactmessage")
                .on("value", (snapshot) => {
                    const list = snapshot.val();
                    const keys = Object.keys(list);
                    const messageList = [];

                    keys.forEach((element) => {
                        messageList.push(list[element]);
                    });
                    setMails(messageList);
                });
        }
    }, [isLogged]);

    const handleOnChange = (e) => {
        const { name, value } = e.target;

        switch (name) {
            case "username":
                setUsername(value);
                break;

            case "password":
                setPassword(value);
                break;

            default:
                break;
        }
    };

    const handleOnSubmit = () => {
        if (username === "admin" && password === "admin") {
            setIsLogged(true);
        }
    };

    const renderMessages = () => (
        <div>
            <header className="major">
                <h2>Mails</h2>
            </header>
            {mails.map((mail, i) => (
                <section key={i}>
                    <header>
                        <h3>{mail.name}</h3>
                        <p>{mail.email}</p>
                        <p className="mail-date">
                            {mail.date && (
                                <i>
                                    {DateTime.fromISO(mail.date).toFormat("FF")}
                                </i>
                            )}
                        </p>
                    </header>
                    <p>{mail.message}</p>
                    <hr />
                </section>
            ))}
        </div>
    );

    const loginForm = () => (
        <form noValidate onSubmit={handleOnSubmit}>
            <div className="row gtr-uniform gtr-50">
                <div className="col-6 col-12-xsmall off-3">
                    <h2 className="off-3">
                        <FormattedMessage id="pageMail.title" />
                    </h2>
                </div>
                <div className="col-6 col-12-xsmall off-3">
                    <input
                        type="text"
                        name="username"
                        id="username"
                        value={username}
                        placeholder="Username"
                        onChange={handleOnChange}
                    />
                </div>
                <div className="col-6 col-12-xsmall off-3">
                    <input
                        type="password"
                        name="password"
                        id="password"
                        value={password}
                        placeholder="password"
                        onChange={handleOnChange}
                    />
                </div>
                <div className="col-12 off-3">
                    <ul className="actions">
                        <li>
                            <input
                                type="submit"
                                value="Login"
                                className="primary"
                            />
                        </li>
                    </ul>
                </div>
            </div>
        </form>
    );

    return (
        <div className="container" id="mail-section">
            {!isLogged ? loginForm() : renderMessages()}
        </div>
    );
};

export default Mail;
