import React, { useEffect } from "react";
import HomepageAppHeader from "../../components/HomepageAppHeader";
import SectionTikkiIntro from "../../components/SectionTikkiIntro";
import templateUtil from "../../../importer/templateUtil";
import templateAnimation from "../../../importer/templateAnimation";
import { setPageTitle } from "../../utils";
import SectionTikkiChallenges from "../../components/SectionTikkiChallenges";

const Tikki = () => {
  useEffect(() => {
    setPageTitle("Tikki");
    templateUtil();
    templateAnimation();
  }, []);

  return (
    <div>
      <HomepageAppHeader />
      <SectionTikkiIntro />
      <SectionTikkiChallenges />
    </div>
  );
};

export default Tikki;
