import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import "./styles.css";

const CookieBanner = () => {
    const [shouldDisplay, setShouldDisplay] = useState(false);
    const containerClasses = shouldDisplay
        ? "container full-container"
        : "container full-container d-none";

    useEffect(() => {
        if (sessionStorage.getItem("cookieConsent") === null) {
            setShouldDisplay(true);
        }
    }, []);

    const hideBanner = () => {
        setShouldDisplay(false);
        sessionStorage.setItem("cookieConsent", "true");
    };

    return (
        <div id="cookie-banner" className={containerClasses}>
            <div className="row">
                <div className="col-12 text-center">
                    <FormattedMessage id="cookiebanner.consent" />{" "}
                    <Link to="/privacy">
                        <FormattedMessage id="cookiebanner.cookiesPolicy" />
                    </Link>{" "}
                    <FormattedMessage id="cookiebanner.userExperience" />
                    <a onClick={hideBanner} className="button primary small mr">
                        OK
                    </a>
                </div>
            </div>
        </div>
    );
};

export default CookieBanner;
