import React from "react";
import { render } from "react-dom";
import * as firebase from "firebase";
import App from "./App";

firebase.initializeApp({
  apiKey: "AIzaSyCkl9-3awTPo-s7gPnJ73q-8ZDDn_UXoCs",
  authDomain: "opexify-website.firebaseapp.com",
  databaseURL: "https://opexify-website.firebaseio.com",
  projectId: "opexify-website",
  storageBucket: "opexify-website.appspot.com",
  messagingSenderId: "97149791292",
  appId: "1:97149791292:web:5f7ec38610e2b82e2f2739",
  measurementId: "G-3MLYCKN0JW",
});
firebase.analytics();

render(<App />, document.getElementById("page-wrapper"));
