import React from "react";
import { FormattedMessage } from "react-intl";
import photoCarl from "../../assets/img/photo-carl-heyerdahl.jpeg";

const SectionThree = () => (
    <section id="three" className="spotlight style3 left">
        <span className="image fit main bottom">
            <img src={photoCarl} alt="photo Carl Heyerdahl" />
        </span>
        <div className="content">
            <header>
                <h3 className="text-primary">
                    <FormattedMessage id="sectionthree.title" />
                </h3>
                <p>
                    <FormattedMessage id="sectionthree.subtitle" />
                </p>
            </header>
            {/* <p>
        Our experienced software consultants will work with all of the key
        parties to fully understand the business goals, its processes and both
        current and future challenges. They will then provide a clear and
        detailed list of requirements that can form the basis of the future
        project.
      </p> */}
            <ul className="alt">
                <li>
                    <FormattedMessage id="sectionthree.list.one" />
                </li>
                <li>
                    <FormattedMessage id="sectionthree.list.two" />
                </li>
                <li>
                    <FormattedMessage id="sectionthree.list.three" />
                </li>
                <li>
                    <FormattedMessage id="sectionthree.list.four" />
                </li>
            </ul>
        </div>
        <a href="#one" className="goto-next scrolly">
            <FormattedMessage id="common.next" />
        </a>
    </section>
);

export default SectionThree;
