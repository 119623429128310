import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/img/logo-opexify-only.png";
import "./styles.css";

const AppHeader = () => (
  <header id="header">
    <h1 id="logo">
      <Link to="/">
        <img src={logo} alt="Logo Opexify" />
      </Link>
    </h1>
  </header>
);

export default AppHeader;
