import React from "react";
import { FormattedMessage } from "react-intl";
import photoRonaldo from "../../assets/img/photo-ronaldo-oliveira.jpeg";
import CanvaBox from "../CanvaBox";
import "./styles.css";

const SectionTikkiChallenges = () => (
    <section id="one" className="spotlight style1 bottom">
        <span className="image fit main">
            <img src={photoRonaldo} alt="Photo Ronaldo Oliveira" />
        </span>
        <div className="content">
            <div className="container">
                <div className="row">
                    <div className="col-12 align-center">
                        <h2 className="text-primary text-uppercase">
                            Your Challenges
                        </h2>
                    </div>
                    <div className="col-6 col-12-medium left-title">
                        <header>
                            <h2>Payment Approval</h2>
                        </header>
                    </div>
                    <div className="col-6 col-12-medium">
                        <CanvaBox>
                            <div className="box-title">Manual execution</div>
                            <p className="box-content">
                                Your process is currently a full paperwork, and
                                orchestrated manually
                            </p>
                            <div className="box-title">Scanty monitoring</div>
                            <p className="box-content">
                                You can't be proactive because it is actually
                                hard for you to know who did what, when and why
                            </p>
                            <div className="box-title">
                                Unsubstantial visibility
                            </div>
                            <p className="box-content">
                                Telling to the client where does his payment
                                stand at is a very huge challenge for you
                            </p>
                            <div className="box-title">
                                Insufficient audit trail
                            </div>
                            <p className="box-content">
                                You are not well equipped to respond to your
                                internal or external auditors regarding your
                                payment processes
                            </p>
                            <div className="box-title">
                                Poor systems integration
                            </div>
                            <p className="box-content">
                                You have dedicated number of your team members
                                to ensure payment data transmission accross your
                                systems
                            </p>
                        </CanvaBox>
                    </div>
                </div>
            </div>
        </div>
        <a href="#services" className="goto-next scrolly">
            <FormattedMessage id="common.next" />
        </a>
    </section>
);

export default SectionTikkiChallenges;
