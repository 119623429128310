import React, { useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import templateAnimation from "../../../importer/templateAnimation";
import templateUtil from "../../../importer/templateUtil";
import AppFooter from "../../components/AppFooter";
import AppHeader from "../../components/AppHeader";
import CookieBanner from "../../components/CookieBanner";
import { scrollToTop, setPageTitle } from "../../utils";
import "./styles.css";

const PrivacyNotice = ({ intl }) => {
    useEffect(() => {
        setPageTitle(intl.formatMessage({ id: "pagePrivacyNotice.title" }));
        templateUtil();
        templateAnimation();
        scrollToTop();
    }, []);

    return (
        <div>
            <AppHeader />
            <section id="privacy-notice">
                <div id="main" className="wrapper style1">
                    <div className="container">
                        <header className="major">
                            <h2>
                                <FormattedMessage id="pagePrivacyNotice.title" />
                            </h2>
                        </header>
                        <div className="row gtr-150">
                            <div className="col-8 col-12-medium imp-medium">
                                <section>
                                    <h3>
                                        <FormattedMessage id="pagePrivacyNotice.sectionDataProtection.title" />
                                    </h3>
                                    <p>
                                        <FormattedMessage
                                            id="pagePrivacyNotice.sectionDataProtection.one"
                                            values={{
                                                we: <b>we</b>,
                                                you: <b>you</b>,
                                            }}
                                        />
                                    </p>
                                </section>
                                <section>
                                    <h3>
                                        <FormattedMessage id="pagePrivacyNotice.sectionPersonalDataCollect.title" />
                                    </h3>
                                    <p>
                                        <FormattedMessage id="pagePrivacyNotice.sectionPersonalDataCollect.one" />
                                    </p>
                                    <p>
                                        <FormattedMessage id="pagePrivacyNotice.sectionPersonalDataCollect.two" />
                                    </p>
                                </section>
                                <section>
                                    <h3>
                                        <FormattedMessage id="pagePrivacyNotice.sectionPersonalData.title" />
                                    </h3>
                                    <p>
                                        <FormattedMessage id="pagePrivacyNotice.sectionPersonalData.one" />
                                    </p>
                                    <p>
                                        <FormattedMessage id="pagePrivacyNotice.sectionPersonalData.two" />
                                    </p>
                                </section>
                                <section>
                                    <h3>
                                        <FormattedMessage id="pagePrivacyNotice.sectionPersonalDataShare.title" />
                                    </h3>
                                    <p>
                                        <FormattedMessage id="pagePrivacyNotice.sectionPersonalDataShare.one" />
                                    </p>
                                </section>
                                <section>
                                    <h3>
                                        <FormattedMessage id="pagePrivacyNotice.sectionPersonalDataTransfer.title" />
                                    </h3>
                                    <p>
                                        <FormattedMessage id="pagePrivacyNotice.sectionPersonalDataTransfer.one" />
                                    </p>
                                </section>
                                <section>
                                    <h3>
                                        <FormattedMessage id="pagePrivacyNotice.sectionPersonalDataKeep.title" />
                                    </h3>
                                    <p>
                                        <FormattedMessage id="pagePrivacyNotice.sectionPersonalDataKeep.one" />
                                    </p>
                                </section>
                                <section>
                                    <h3>
                                        <FormattedMessage id="pagePrivacyNotice.sectionRights.title" />
                                    </h3>
                                    <p>
                                        <FormattedMessage id="pagePrivacyNotice.sectionRights.one" />
                                    </p>
                                    <ul>
                                        <li>
                                            <FormattedMessage
                                                id="pagePrivacyNotice.sectionRights.list.one"
                                                values={{
                                                    intro: (
                                                        <b>right of access:</b>
                                                    ),
                                                }}
                                            />
                                        </li>
                                        <li>
                                            <FormattedMessage
                                                id="pagePrivacyNotice.sectionRights.list.two"
                                                values={{
                                                    intro: (
                                                        <b>
                                                            right to
                                                            rectification:
                                                        </b>
                                                    ),
                                                }}
                                            />
                                        </li>
                                        <li>
                                            <FormattedMessage
                                                id="pagePrivacyNotice.sectionRights.list.three"
                                                values={{
                                                    intro: (
                                                        <b>right to erasure:</b>
                                                    ),
                                                }}
                                            />
                                        </li>
                                        <li>
                                            <FormattedMessage
                                                id="pagePrivacyNotice.sectionRights.list.four"
                                                values={{
                                                    intro: (
                                                        <b>
                                                            right to restriction
                                                            of processing:
                                                        </b>
                                                    ),
                                                }}
                                            />
                                        </li>
                                        <li>
                                            <FormattedMessage
                                                id="pagePrivacyNotice.sectionRights.list.five"
                                                values={{
                                                    intro: (
                                                        <b>right to object:</b>
                                                    ),
                                                }}
                                            />
                                        </li>
                                        <li>
                                            <FormattedMessage
                                                id="pagePrivacyNotice.sectionRights.list.six"
                                                values={{
                                                    intro: (
                                                        <b>
                                                            right to data
                                                            portability:
                                                        </b>
                                                    ),
                                                }}
                                            />
                                        </li>
                                        <li>
                                            <FormattedMessage
                                                id="pagePrivacyNotice.sectionRights.list.seven"
                                                values={{
                                                    intro: (
                                                        <b>
                                                            right to lodge a
                                                            complaint:
                                                        </b>
                                                    ),
                                                }}
                                            />
                                        </li>
                                        <li>
                                            <FormattedMessage
                                                id="pagePrivacyNotice.sectionRights.list.eight"
                                                values={{
                                                    intro: (
                                                        <b>
                                                            right to withdraw
                                                            your consent at any
                                                            time:
                                                        </b>
                                                    ),
                                                }}
                                            />
                                        </li>
                                    </ul>
                                    <p>
                                        <FormattedMessage
                                            id="pagePrivacyNotice.sectionRights.two"
                                            values={{
                                                email: (
                                                    <a href="mailto:hello@opexify.io">
                                                        hello@opexify.io
                                                    </a>
                                                ),
                                            }}
                                        />
                                    </p>
                                    <address>
                                        Opexify
                                        <br />
                                        5, rue des Champs
                                        <br />
                                        L-4718 Pétange
                                        <br />
                                        Luxembourg
                                        <br />
                                    </address>
                                    <p>
                                        <FormattedMessage id="pagePrivacyNotice.sectionRights.three" />
                                    </p>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <AppFooter />
            <CookieBanner />
        </div>
    );
};

export default injectIntl(PrivacyNotice);
