import React, { useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { AppContext } from "../../context/app-context";
import logo from "../../assets/img/logo-opexify-only.png";
import { LANGUAGE } from "../../utils";
import "./styles.css";

const MOBILE_MAX_WIDTH = 736;

const HomepageAppHeader = () => {
  const { language, setLanguage } = useContext(AppContext);
  const refHeader = useRef(null);

  const setFrenchLanguage = () => {
    setLanguage(LANGUAGE.FR);
  };

  const setEnglishLanguage = () => {
    setLanguage(LANGUAGE.EN);
  };

  const setLuxemburgishLanguage = () => {
    setLanguage(LANGUAGE.LU);
  };

  const setActiveMobileLanguage = () => {
    document.querySelectorAll("#navPanel a.link.depth-1").forEach((item) => {
      item.setAttribute("href", "#page-wrapper");

      if (item.text.toLowerCase() === language.toLowerCase()) {
        item.classList.add("selected");
      } else {
        item.classList.remove("selected");
      }
    });
  };

  const resizeObserver = new ResizeObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.contentRect.width < MOBILE_MAX_WIDTH) {
        setActiveMobileLanguage();
      }
    });
  });

  const initCheckMobileView = () => {
    const list = document.querySelectorAll("#navPanel a.link.depth-1");

    if (window.innerWidth < MOBILE_MAX_WIDTH && list.length > 0) {
      setActiveMobileLanguage();
    } else {
      setTimeout(() => {
        initCheckMobileView();
      }, 1000);
    }
  };

  useEffect(() => {
    resizeObserver.observe(refHeader.current);
  }, [language]);

  useEffect(() => {
    initCheckMobileView();
  }, []);

  return (
    <header id="header" ref={refHeader}>
      <h1 id="logo">
        <Link to="/">
          <img src={logo} alt="Logo Opexify" />
        </Link>
      </h1>
      <nav id="nav">
        <ul>
          <li>
            <a href="#solutions" className="scrolly">
              <FormattedMessage id="homepageappheader.menu.solutions" />
            </a>
          </li>
          <li className="opener layout">
            <a href="#services" className="scrolly">
              <FormattedMessage id="homepageappheader.menu.services" />
            </a>
          </li>
          <li>
            <a href="#one" className="scrolly">
              <FormattedMessage id="homepageappheader.menu.mission" />
            </a>
          </li>
          <li>
            <a href="#four" className="scrolly">
              <FormattedMessage id="homepageappheader.menu.team" />
            </a>
          </li>
          {/*<li>
            <a href="#banner" className="scrolly">
              <FormattedMessage id="homepageappheader.menu.language" />
            </a>
            <ul id="lng-selector">
              <li>
                <span
                  onClick={() => {
                    setEnglishLanguage();
                  }}
                >
                  <a
                    className={language === LANGUAGE.EN ? "selected" : ""}
                    href="#"
                  >
                    {LANGUAGE.EN.toUpperCase()}
                  </a>
                </span>
              </li>
              <li>
                <span
                  onClick={() => {
                    setFrenchLanguage();
                  }}
                >
                  <a
                    className={language === LANGUAGE.FR ? "selected" : ""}
                    href="#"
                  >
                    {LANGUAGE.FR.toUpperCase()}
                  </a>
                </span>
              </li>
              <li>
                <span
                  onClick={() => {
                    setLuxemburgishLanguage();
                  }}
                >
                  <a
                    className={language === LANGUAGE.LU ? "selected" : ""}
                    href="#"
                  >
                    {LANGUAGE.LU.toUpperCase()}
                  </a>
                </span>
              </li>
            </ul>
          </li>*/}
        </ul>
      </nav>
    </header>
  );
};

export default HomepageAppHeader;
