import React from "react";
import "./styles.css";

const CanvaBox = ({ children }) => (
  <div className="canvabox">
    <div className="container-fluid">
      <div className="row">
        <div className="col-1 white-box" />
        <div className="col-11 primary-box">{children}</div>
      </div>
    </div>
  </div>
);

export default CanvaBox;
