import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import "./styles.css";

const AppFooter = () => (
    <footer id="footer">
        <ul className="icons d-none">
            <li>
                <a
                    href="https://twitter.com/opexify_io"
                    className="icon brands alt fa-twitter"
                    target="_blank"
                >
                    <span className="label">Twitter</span>
                </a>
            </li>
            <li>
                <a
                    href="https://www.linkedin.com/company/opexify/"
                    className="icon brands alt fa-linkedin-in"
                    target="_blank"
                >
                    <span className="label">LinkedIn</span>
                </a>
            </li>
            <li>
                <a
                    href="mailto:hello@opexify.io?subject=Connection from Web Site"
                    className="icon solid alt fa-envelope"
                    target="_blank"
                >
                    <span className="label">Email</span>
                </a>
            </li>
        </ul>
        <ul className="icons">
            <li>
                <Link to="/privacy">
                    <FormattedMessage id="appfooter.cookiesPolicy" />
                </Link>
            </li>
            <li>
                <Link to="/privacy-notice">
                    <FormattedMessage id="appfooter.privacyNotice" />
                </Link>
            </li>
        </ul>
        <ul className="copyright">
            <li>
                &copy; <FormattedMessage id="appfooter.rights" />
            </li>
            <li>
                <FormattedMessage id="appfooter.authorisation" />
            </li>
            <li>
                <FormattedMessage id="appfooter.registration" />
            </li>
            <li>
                <FormattedMessage id="appfooter.vat" />
            </li>
        </ul>
    </footer>
);

export default AppFooter;
