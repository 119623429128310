import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import photoAdeoluEletu from "../../assets/img/adeolu-eletu-unsplash.jpg";

const SectionResources = () => (
    <section id="resources" className="spotlight style2 left">
        <span className="image fit main bottom">
            <img src={photoAdeoluEletu} alt="Photo Adeolu Eletu" />
        </span>
        <div className="content">
            <header>
                <h2 className="text-primary text-uppercase">
                    <FormattedMessage id="sectionresources.title" />
                </h2>
                <div className="ressource">
                    <h3>
                        <FormattedMessage id="sectionresources.article.title" />
                    </h3>
                    <p>
                        <FormattedMessage id="sectionresources.article.author" />
                    </p>
                    <p>
                        <a
                            href="https://medium.com/@opexify/what-is-robotic-process-automation-rpa-and-how-can-it-benefit-your-business-organization-f7c72064c2ff"
                            target="_blank"
                            className="button"
                        >
                            <FormattedMessage id="common.more" />
                        </a>
                    </p>
                    <hr />
                </div>
            </header>
            <p>
                <Link to="/blog">
                    <FormattedMessage id="sectionresources.resources" />
                </Link>
            </p>

            {/*
      <header>
        <h2 className="text-primary text-uppercase">Our Resources</h2>
        <div className="ressource">
          <h3>Why APIs Are Fundamental to Your Business</h3>
          <p>By Yannick Huchard</p>
          <p>
            <a
              href="https://medium.com/@yannick.huchard/why-apis-are-fundamental-to-your-business-613184eb4f8"
              target="_blank"
              className="button"
            >
              Read More
            </a>
          </p>
          <hr />
        </div>
      </header>
      */}
        </div>
        <a href="#four" className="goto-next scrolly">
            <FormattedMessage id="common.next" />
        </a>
    </section>
);

export default SectionResources;
