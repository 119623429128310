import React, { useEffect } from "react";
import templateAnimation from "../../../importer/templateAnimation";
import templateUtil from "../../../importer/templateUtil";
import HomepageAppHeader from "../../components/HomepageAppHeader";
import { setPageTitle } from "../../utils";
import edithSidjou from "../../assets/img/edith-sidjou.jpeg";
import "./styles.css";

const Consultants = () => {
  useEffect(() => {
    setPageTitle("Consultants");
    templateUtil();
    templateAnimation();
  }, []);

  return (
    <div id="consultants-page">
      <HomepageAppHeader />
      <div class="wrapper style1">
        <div class="container">
          <header class="major">
            <h2>Our Consultants</h2>
          </header>

          <section id="content">
            <h3>Edith Sidjou</h3>
            <p>
              <span className="image left">
                <img src={edithSidjou} alt="Edith Sidjou" />
              </span>
              Edith SIJOU is a <span className="text-primary">Jurist</span> and{" "}
              <span className="text-primary">
                certified international privacy professional (CIPP/E)
              </span>
              . Her legal expertise and her knowledge of data protection
              regulations enables her to support both individuals and companies
              from various sectors in their efforts to comply with the General
              Data Protection Regulation (GDPR).
            </p>
            <p>
              Edith advises and assists Data Controllers and Data Processors on
              operational data protection matters (gap analysis, Data protection
              impact assessment, Privacy by Design, data protection awareness
              and training, data retention analysis, drafting of internal
              privacy policies and procedures, etc.).
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Consultants;
