import React from "react";

import { LANGUAGE } from "../utils";

export const appContext = {
    language: LANGUAGE.EN,
    setLanguage: () => {},
};

export const AppContext = React.createContext(appContext);
